import React from "react";
import { Helmet } from "react-helmet";

const thankYouGeneral = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Consult FGC | Digital Marketing Agency | Website Design & Development</title>

        <meta
          name="description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        />
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
      <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-12 lg:mt-16">
        <div class="text-center">
          <img
            class="mx-auto w-1/3"
            src="https://imgix.cosmicjs.com/fc7453b0-b6fb-11ea-b20f-f350ffc5594c-ConsultFGCLogosq.jpg"
            alt="Consult FGC Logo"
          />
          <div className="mx-auto flex w-6/12 mb-4"></div>
          <h2 class="text-2xl md:text-4xl tracking-tight md:leading-12 font-extrabold text-blue-900 sm:text-5xl sm:leading-none md:text-6xl">
            Your Submission Was Received Successfully!
          </h2>
          <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            One of our representatives at ConsultFGC will get in touch with you
            in 24-48 hours if needed, thank you for your patience.
          </p>

          <div class="rounded-md">
            <div class="mt-10 rounded-md sm:flex-shrink-0">
              <a
                href="/"
                class="w-full px-20 py-5 text-lg font-medium rounded-md text-white bg-gradient-to-l from-secondaryBlue to-primaryBlue hover:opacity-70"
              >
                Return Home
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default thankYouGeneral;
